import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppContext } from "../libs/contextLib";

export default function PrivateRoute() {
    const { store } = useAppContext();
    const auth = store.user.isAuthenticated; // determine if authorized, from context or however you're doing it
    console.log('Auth state', auth)
    
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth ? <Outlet /> : <Navigate to="/login" />;
}