import React, { Suspense, lazy, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner'
import PrivateRoute from "./auth/PrivateRoute";

const Home = lazy(() => import("./common/Home"));
const Dashboard = lazy(() => import("./common/Dashboard"));
const NotFound = lazy(() => import("./common/NotFound"));
const Login = lazy(() => import("./auth/Login"));

export default function AppRoutes() {
    return (
        <Suspense fallback={
            <div className="align-self-center">
                <Spinner animation="border" variant="secondary" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        }>
            <Routes>
                <Route exact path='/' element={<PrivateRoute/>}>
                    <Route exact path='/' element={<Home/>}/>
                    <Route exact path='/dashboard' element={<Dashboard />}/>
                </Route>
                <Route exact path='/login' element={<Login/>}/>
                <Route exact path='*' element={<NotFound/>}/>
            </Routes>
        </Suspense>
    );
}