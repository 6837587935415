
function messageExists(messages, id) {
    return messages.some(message =>
        id === message.id)
}

export default function reducer(state, action) {
    switch(action.type) {
        case 'ADD_ALERT':
            if (!messageExists(state.alertMessages, action.id)) {
                return {
                    ...state,
                    alertMessages: [
                        ...state.alertMessages,
                        {
                            id: action.id,
                            variant: action.variant, 
                            message: action.message
                        }, 
                    ]
                }
            }
            else {
                return state
            }
        case 'REMOVE_ALERT':
            return {
                ...state,
                alertMessages: state.alertMessages.filter(message => message.id !== action.id)
            }
        case 'AUTH_USER_DATA':
            return {
                ...state,
                user: {
                    ...state.user,
                    username: action.username,
                    isAuthenticated: action.isAuthenticated,
                    first_name: action.first_name,
                    last_name: action.last_name,
                    email: action.email,
                    phone_number: action.phone_number,
                    emergency_contacts: action.emergency_contacts,
                    medical_info: action.medical_info,
                    birth_date: action.birth_date,
                    status: action.status,
                    timezone: action.timezone,
                    subscription_id: action.subscription_id,
                    subscription_status: action.subscription_status,
                    billing_date: action.billing_date,
                    card_data: action.card_data,
                    paid: action.paid
                }
            }
        case 'SET_USERNAME':
            return {
                ...state,
                user: {
                    ...state.user,
                    username: action.username,
                    isAuthenticated: action.isAuthenticated,
                }
            }
        case 'LOGOUT':
            return {
                ...state,
                user: action.user
            }
        case 'SET_USER_DATA':
            return {
                ...state,
                user: {
                    ...state.user,
                    first_name: action.first_name,
                    last_name: action.last_name,
                    email: action.email,
                    phone_number: action.phone_number,
                    emergency_contacts: action.emergency_contacts,
                    medical_info: action.medical_info,
                    birth_date: action.birth_date,
                    status: action.status,
                    timezone: action.timezone,
                    subscription_id: action.subscription_id,
                    subscription_status: action.subscription_status,
                    billing_date: action.billing_date,
                    card_data: action.card_data,
                    paid: action.paid
                }
            }
        case 'SET_SUBSCRIPTION_STATUS':
            return {
                ...state,
                user: {
                    ...state.user,
                    subscription_status: action.subscription_status
                }
            }
        case 'UPDATE_USER_DATA':
            return {
                ...state,
                user: {
                    ...state.user,
                    first_name: action.first_name,
                    last_name: action.last_name,
                    email: action.email,
                    phone_number: action.phone_number
                }
            }
        case 'ADD_CONTACT':
            return {
                ...state,
                user: {
                    ...state.user,
                    emergency_contacts: [
                        ...state.user.emergency_contacts,
                        {
                            id: action.id,
                            name: action.name,
                            phone_number: action.phone_number,
                            primary: action.primary
                        }
                    ]
                }
            }
        case 'EDIT_CONTACT':
            return {
                ...state,
                user: {
                    ...state.user,
                    emergency_contacts:[...state.user.emergency_contacts.filter(contact => contact.id !== action.id), {
                        id: action.id,
                        name: action.name,
                        phone_number: action.phone_number,
                        primary: action.primary
                    }]
                }
            }
        case 'UPDATE_CONTACTS':
            return {
                ...state,
                user: {
                    ...state.user,
                    emergency_contacts: action.emergency_contacts
                }
            }
        case 'REMOVE_CONTACT':
            const emergencyContacts = state.user.emergency_contacts.filter(contact => contact.id !== action.id)
            console.log(emergencyContacts)
            return {
                ...state,
                user: {
                    ...state.user,
                    emergency_contacts: emergencyContacts
                }
            }
        default:
            return state
    }
}