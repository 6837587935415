export const initialState = {
    alertMessages: [],
    user: {
        username: null,
        isAuthenticated: false,
        // onboarded: true,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        medical_info: null,
        birth_date: null,
        status: null,
        timezone: 'Paris/Europe',
        emergency_contacts: [],
        subscription_id: null,
        subscription_status: null,
        billing_date: null,
        card_data: null
    }
};