import React, {useReducer} from "react";
import { AppContext } from "./libs/contextLib";
import { useNavigate, Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import AppRoutes from "./Routes";
import ErrorBoundary from "./common/ErrorBoundary";
import reducer from "./reducers/reducer";
import { initialState } from "./reducers/initialState";
import { logoutUser } from "./reducers/actions";
import { Auth } from "aws-amplify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function App() {
  const [store, dispatch] = useReducer(reducer, initialState);
  let navigate = useNavigate();
  console.log('App state', store)

  async function handleLogout() {
    await Auth.signOut();
    dispatch(logoutUser)
    navigate('/login');
  }
  
  return (
    <>
    <Navbar className="border-bottom" sticky="top" bg="white" expand="lg" variant="light">
        <Navbar.Brand>
          <Link to="/" className="navbar-brand ps-3">
            <FontAwesomeIcon icon={['far', 'compass']} />
            <span className="ps-2">Lost in the Wild - Admin Console</span>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
          <Nav className="pe-3">
            {store.user.isAuthenticated
              ? <>
                  <Link to="/" className="nav-link">Dashboard</Link>
                  <Link to="/dashboard" className="nav-link">Trip Viewer</Link>
                  <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                </>
              : <>
                  <Link to="login" className="nav-link">Login</Link>
                </>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <ErrorBoundary>
        <AppContext.Provider value={{ navigate, store, dispatch }}>
          <AppRoutes />
        </AppContext.Provider>
      </ErrorBoundary>
    </>
  );
}

export default App;