export const missingEmergencyContacts = {
    type: 'ADD_ALERT',
    id: "missingEmergencyContacts",
    variant: "primary",
    message: "Please create an emergency contact before you create an activity."
}

export const subscriptionCancelled = {
    type: "ADD_ALERT",
    id: "subscriptionCancelled",
    variant: "success",
    message: "Subscription Cancelled"
}

export const subscriptionRestarted = {
    type: "ADD_ALERT",
    id: "subscriptionRestarted",
    variant: "success",
    message: "Subscription Restarted"
}

export const billingSuccess = {
    type: "ADD_ALERT",
    id: "billingSuccess",
    variant: "success",
    message: "Your payment was successful."
}

export function removeAlert(id) {
    return {
        type: 'REMOVE_ALERT',
        id: id
    }
}

export function setUsername(username) {
    return {
        type: 'SET_USERNAME',
        username: username,
        isAuthenticated: true,
    }
}

export const logoutUser = {
    type: 'LOGOUT',
    user: {
        username: null,
        isAuthenticated: false,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        medical_info: null,
        birth_date: null,
        status: null,
        timezone: null,
        emergency_contacts: [],
        subscription_id: null,
        subscription_status: null,
        billing_date: null,
        card_data: null,
        paid: false
    }
}

export function setUserData(user) {
    return {
        type: 'SET_USER_DATA',
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone_number: user.phone_number,
        emergency_contacts: user.emergency_contacts,
        medical_info: user.medical_info,
        birth_date: user.birth_date,
        status: user.status,
        timezone: user.timezone,
        subscription_id: user.subscription_id,
        subscription_status: user.subscription_status,
        billing_date: user.billing_date,
        card_data: user.card_data,
        paid: user.paid
    }
}

export function authenticatedUserData(user) {
    return {
        type: 'AUTH_USER_DATA',
        username: user.id,
        isAuthenticated: true,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone_number: user.phone_number,
        emergency_contacts: user.emergency_contacts,
        medical_info: user.medical_info,
        birth_date: user.birth_date,
        status: user.status,
        timezone: user.timezone,
        subscription_id: user.subscription_id,
        subscription_status: user.subscription_status,
        billing_date: user.billing_date,
        card_data: user.card_data,
        paid: user.paid
    }
}

export function setSubscriptionStatus(status) {
    return {
        type: 'SET_SUBSCRIPTION_STATUS',
        subscription_status: status
    }
}

export function updateUserData(user) {
    return {
        type: 'UPDATE_USER_DATA',
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone_number: user.phone_number
    }
}

export function addContact(contact) {
    return {
        type: 'ADD_CONTACT',
        id: contact.id,
        name: contact.name,
        phone_number: contact.phone_number,
        primary: contact.primary
    }
}

export function editContact(contact) {
    return {
        type: 'EDIT_CONTACT',
        id: contact.id,
        name: contact.name,
        phone_number: contact.phone_number,
        primary: contact.primary
    }
}

export function updateContacts(contacts_list) {
    return {
        type: 'UPDATE_CONTACTS',
        emergency_contacts: contacts_list
    }
}

export function removeContact(contact_id) {
    return {
        type: 'REMOVE_CONTACT',
        id: contact_id
    }
}
