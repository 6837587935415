
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fontawesome';
import App from './App';
import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    // mandatorySignIn: true,
    region:  "eu-west-1",
    userPoolId: "eu-west-1_ixzOtvYLB",
    userPoolWebClientId: "21s491umj6pf9s2epaljlis47s",
    custom_header: async () => { 
      return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
    }
  },
  API: {
    endpoints: [
      {
        name: "lostinthewild",
       // endpoint: process.env.REACT_ADMIN_APP_API_URL,
       // region: process.env.REACT_ADMIN_APP_API_REGION
       endpoint: "https://admin-api.simonsandbox.lostinthewild.co",
       region: "eu-west-1"
      },
    ]
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>
);
